"use client";

import {
  LinkCard,
  type LinkCardProps
} from "$src/components/link-card/link-card";
import { useMeasure } from "@react-hookz/web";

export function BlogPost({
  autoRows = true,
  showDate,
  ...props
}: { autoRows?: boolean } & LinkCardProps) {
  const [measurements, ref] = useMeasure<HTMLDivElement>();

  return (
    <LinkCard
      showDate={showDate}
      ref={ref}
      style={
        autoRows
          ? {
              gridRow: `span ${Number(measurements?.height.toFixed()) + 20}`
            }
          : {}
      }
      {...props}
    />
  );
}
