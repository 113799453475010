import { cx } from "@tracksuit/utils";
import { format, parseISO } from "date-fns";
import type { ComponentProps } from "react";
import { isValidDate } from "./lib/is-valid-date";

type DateProps = {
  dateString?: string;
} & ComponentProps<"div">;

/**
 * @component
 * For formating the date from YYYY-MM-DD to Month Date Year
 */
export function ValidateAndFormatDate({
  dateString,
  className,
  ...props
}: DateProps) {
  const date = parseISO(dateString || "");

  if (!isValidDate(dateString)) {
    return (
      <div className={cx(className)} {...props}>
        {dateString || ""}
      </div>
    );
  }

  const dayOfMonth = date.getDate();
  let daySuffix = "th";

  if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
    daySuffix = "st";
  } else if (dayOfMonth === 2 || dayOfMonth === 22) {
    daySuffix = "nd";
  } else if (dayOfMonth === 3 || dayOfMonth === 23) {
    daySuffix = "rd";
  }

  const formattedDate = format(date, "MMMM d, yyyy");

  const formattedDateWithSuffix = `${formattedDate.replace(
    dayOfMonth.toString(),
    dayOfMonth + daySuffix
  )}`;

  return (
    <div className={cx(className)} {...props}>
      {formattedDateWithSuffix}
    </div>
  );
}
