"use client";
import { cx } from "@tracksuit/utils";
import { sanityImage } from "$src/lib/sanity";
import { Ticker } from "../ticker/ticker";
import { type ComponentProps } from "react";
import styles from "./logo-list.module.css";
import type { SanityImageAssetDocument } from "next-sanity";

export type Logo = {
  logo: SanityImageAssetDocument;
  name: string;
};

export type LogoListProps = {
  headerText: string;
  logos?: Logo[];
} & ComponentProps<"div">;

export function LogoList({
  className,
  headerText,
  logos,
  ...props
}: LogoListProps) {
  return (
    <>
      {logos?.filter(Boolean).length && (
        <section {...props} className={cx(className, styles["logos-wrapper"])}>
          <h2 className={styles.header}>{headerText}</h2>
          <Ticker>
            <div className={cx(styles.logos)}>
              {logos.filter(Boolean).map(({ logo, name }: Logo) => (
                <img
                  key={name}
                  loading="lazy"
                  {...sanityImage(logo)}
                  alt={`${name} logo`}
                  className={styles.logo}
                />
              ))}
            </div>
          </Ticker>
        </section>
      )}
    </>
  );
}
