"use client";
import type { ReactNode } from "react";
import Marquee from "react-fast-marquee";

export type TickerProps = Readonly<{
  /** Whether ticker... ticks */
  enabled?: boolean;
  /** Children for ticker */
  children: ReactNode;
}>;

/**
 * @component
 * Rotating ticker of content
 */
export function Ticker({ enabled = true, children }: TickerProps) {
  return (
    <Marquee autoFill play={enabled}>
      {children}
    </Marquee>
  );
}
