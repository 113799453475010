import { resolveLink, sanityImage } from "$src/lib/sanity";
import type { SanityImageAssetDocument } from "next-sanity";
import { cx } from "@tracksuit/utils";
import Link, { type LinkProps } from "next/link";
import { forwardRef, type CSSProperties } from "react";
import { isValidDate } from "../validate-and-format-date/lib/is-valid-date";
import { ValidateAndFormatDate } from "../validate-and-format-date/validate-and-format-date";
import styles from "./link-card.module.css";

export type LinkCardProps = {
  alt?: string;
  image: SanityImageAssetDocument;
  title: string;
  link: any;
  portrait?: boolean;
  large?: boolean;
  showDate?: boolean;
  subtitle?: string;
  className?: string;
  style?: CSSProperties;
  region: string;
  styleTitleClass?: string | false;
} & Omit<LinkProps, "href">;

type LinkCardDateProps = Pick<LinkCardProps, "subtitle">;

const LinkCardDate = ({ subtitle }: LinkCardDateProps) => {
  const isSubtitleDate = isValidDate(subtitle);
  return isSubtitleDate ? (
    <ValidateAndFormatDate dateString={subtitle} className={styles.date} />
  ) : (
    <div className={styles.date}>{subtitle}</div>
  );
};

/**
 * @component
 * Card view of each blog post
 */
export const LinkCard = forwardRef(function LinkCard(
  {
    alt,
    image,
    title,
    link,
    portrait = true,
    large = false,
    showDate = true,
    subtitle,
    styleTitleClass,
    className,
    region,
    ...props
  }: LinkCardProps,
  ref
) {
  return (
    <Link
      href={resolveLink(region, link)}
      className={`${cx(
        styles.container,
        portrait ? styles.portrait : styles.landscape,
        large && styles.large,
        className
      )}`}
      ref={ref as any}
      {...props}
    >
      <img
        {...sanityImage(image)}
        loading="lazy"
        alt={alt ?? title}
        className={styles.image}
      />
      <h3 className={cx(styles.title, styleTitleClass)}>{title}</h3>

      {showDate && <LinkCardDate subtitle={subtitle} />}
    </Link>
  );
});
