"use client";

import "swiper/css";
import "swiper/css/scrollbar";
import { A11y, FreeMode, Mousewheel, Scrollbar } from "swiper/modules";
import { cx } from "@tracksuit/utils";
import { resolveLink, sanityImage } from "$src/lib/sanity";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCallback, useState, type ComponentProps } from "react";
import Link from "next/link";
import styles from "./image-list.module.css";
import swiperCommon from "$src/lib/swiper-common";

export type ImageListProps = {
  /** No description */
  heading: string;
  region: string;
  caseStudies: any[];
} & ComponentProps<"div">;

/**
 * @component
 * Image list component for home page
 */
export function ImageList({
  heading,
  caseStudies,
  className,
  region,
  ...props
}: ImageListProps) {
  const [show, setShow] = useState<boolean>(false);
  const init = useCallback(() => {
    setShow(true);
  }, []);

  return (
    <>
      {caseStudies && caseStudies.length > 0 ? (
        <div className={styles.container}>
          {heading ? <h2 className={styles.header}>{heading}</h2> : null}

          <Swiper
            {...swiperCommon}
            scrollbar={{
              el: ".imagelist-scrollbar",
              draggable: true,
              dragClass: "imagelist-swiper-drag",
              horizontalClass: "imagelist-swiper-scrollbar",
              enabled: true
            }}
            spaceBetween={20}
            slidesOffsetBefore={40}
            slidesOffsetAfter={40}
            slidesPerView={2}
            freeMode={true}
            onSwiper={init}
            autoHeight={true}
            modules={[FreeMode, Mousewheel, Scrollbar, A11y]}
            breakpoints={{
              900: {
                slidesPerView: 5,
                slidesOffsetBefore: 64,
                slidesOffsetAfter: 64
              },
              1440: {
                slidesPerView: 6
              }
            }}
          >
            {caseStudies.map((post) => (
              <SwiperSlide key={post._key} className={styles.image}>
                <Link
                  href={resolveLink(region, post.link)}
                  className={cx(
                    styles["fade-in"],
                    show ? styles["show"] : styles["hide"]
                  )}
                >
                  <img
                    width={224}
                    loading="lazy"
                    alt={post.title}
                    {...sanityImage(post.image)}
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={styles["imagelist-scrollbar-container"]}>
            <div className="imagelist-scrollbar"></div>
          </div>
        </div>
      ) : null}
    </>
  );
}
